// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../water_galon/Water Galon.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../watermelon_days/Watermelon Days.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*media breaks*/\n:root {\n  --h1: 6rem;\n}\n@media (max-width: 600px) {\n  :root {\n    --h1: 1.5rem;\n  }\n}\n\n@font-face {\n  font-family: pawwash;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n@font-face {\n  font-family: diy_pawwash;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.home {\n  min-height: 100vh;\n}\n.home h1 {\n  text-transform: uppercase;\n  font-family: pawwash;\n  font-size: var(--h1);\n  color: #fff;\n  -webkit-text-stroke: 1.5px #000;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}", "",{"version":3,"sources":["webpack://./src/components/styles.scss/variables.scss","webpack://./src/components/styles.scss/home.scss"],"names":[],"mappings":"AAAA,eAAA;AAKA;EACI,UAAA;ACHJ;ADKI;EAHJ;IAIQ,YAAA;ECFN;AACF;;ADKA;EACI,oBAAA;EACA,4CAAA;ACFJ;ADKA;EACI,wBAAA;EACA,4CAAA;ACHJ;AAdA;EACI,iBAAA;AAgBJ;AAfI;EACI,yBAAA;EACA,oBAAA;EACA,oBAAA;EACA,WAAA;EACA,+BAAA;EDcJ,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;ACIJ","sourcesContent":["/*media breaks*/\n$mobile: 600px;\n$tablet: 768px;\n$laptop: 900px;\n\n:root{\n    --h1: 6rem;\n\n    @media(max-width: $mobile){\n        --h1: 1.5rem;\n    }\n}\n\n@font-face {\n    font-family: pawwash;\n    src: url('../../../water_galon/Water\\ Galon.ttf');\n}\n\n@font-face {\n    font-family: diy_pawwash;\n    src: url('../../../watermelon_days/Watermelon\\ Days.ttf');\n}\n\n@mixin center__page{\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n}","@import './variables.scss';\n\n\n.home{\n    min-height: 100vh;\n    h1{\n        text-transform: uppercase;\n        font-family: pawwash;\n        font-size: var(--h1);\n        color: #fff;\n        -webkit-text-stroke: 1.5px #000;\n        @include center__page;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
